@import '../../mixins.scss';


.Description {
    color: #4d4d4d;
    line-height: 1.6em;
    font-size: 16px;
    font-weight: 300;
    margin-top: 25px;
}

.Observations {
    width: 200px;
    max-width: 200px;
    @include TruncateText;
}

.ProcessesGrid {
    background-color: #eee;
    padding: 25px 0;
}

.ProcessPreview {
    height: 250px;
    cursor: pointer;
}

.Pagination {
    margin: 25px 0;
}
