.EmptyStateContainer {
    background-color: transparent;
    height: 70vh;

    .Icon {
	font-size: 76px;
	color: #999;
	margin-bottom: 15px;
	cursor: default;
    }

    .Title {
	color: #666;
	font-size: 28px;
	margin-bottom: 15px;
	font-weight: bold;
    }

    .Message {
	color: #4a4a4a;
	font-size: 18px;
	font-weight: light;
    }
}
