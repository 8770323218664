@import '../../mixins.scss';


.Description {
    width: 350px;
    max-width: 350px;
    @include TruncateText;
}

.Observations {
    width: 200px;
    max-width: 200px;
    @include TruncateText;
}

.IssuesGrid {
    background-color: #eee;
    padding: 25px 0;
}

.Pagination {
    margin: 25px 0;
}
