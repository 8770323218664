.Login {
    height: 100vh;
    background-color: #EFEFEF;

    h1 {
	text-transform: uppercase;
	color: #4a4a4a;
	text-align: center;
	margin-bottom: 25px;
	font-size: 70px;
	font-weight: 300;
	opacity: 0.7;
    }

    button {
	margin-top: 25px;
    }

    .LoginForm {
	padding: 10px;

	& > div {
	    margin: 15px 0;
	}
    }

    .ValidationError {
	padding-top: 25px;
	color: rgb(221, 44, 0);
	font-size: smaller;
    }
}
