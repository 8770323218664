.ContentWrapper {
    padding: 24px 24px;
    height: 100%;
}

.IssuePreview {
    height: 275px;
    cursor: pointer;
    background-color: red;

    .IssueId {
	color: #4a4a4a;
	font-size: 12px
    }

    .Nature {
	background-color: #eee;
	font-size: 12px;
	padding: 6px;
	letter-spacing: 1px;
	border-radius: 4px;
    }

    h3 {
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 20px;
	color: #808080;
	font-weight: 400;
	margin-top: 20px;
	margin-bottom: 4px;
    }

    h4 {
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 16px;
	font-weight: 300;
	color: #808080;
    }


    // https://stackoverflow.com/a/8837382

    .Description {
	color: #4d4d4d;
	line-height: 1.6em;
	font-size: 16px;
	font-weight: 300;
	margin-top: 25px;
    }

    .ResolvedState {
	padding-left: 5px;
	height: 20px;
	color:#17e669ff;
    }

    .CreatedState {
	padding-left: 5px;
	height: 20px;
	color:  #b3b3b3ff;
    }

    .PendingState {
	padding-left: 5px;
	height: 20px;
	color:  yellow;
    }

    .ProgressState {
	padding-left: 5px;
	height: 20px;
	color: #00a1ff;
    }

    .CanceledState {
	padding-left: 5px;
	height: 20px;
	color:  red;
    }

    .Footer {

	font-size: 12px;
	color: #4d4d4d;

	svg {
	    font-size: 20px;
	    fill: #cccccc;
	    padding-right: 8px;

	}

    }

}

// $ticket-new-color: #b3b3b3ff;
// $ticket-progress-color: #00a1ff;
// $ticket-closed-color: #17e669ff;
