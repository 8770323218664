.Header {
    padding: 25px;

    a {
	color: #000;
	text-decoration: none;
	padding: 0 15px;
	text-transform: uppercase;
	font-weight: bold;
    }

    a:last-of-type {
	margin-right: 100px;
    }

    svg {
	padding: 0 5px;
    }

    .Active {
	text-decoration: underline;
    }

}

.MobileHeader {
    background-color: #ccc;
    padding: 16px;

    .Search {
	width: 200px;
    }

}

.SelectedOrganization {
    background-color: #e4e4e4 !important;
}
